@font-face {
  font-family: 'Sagire';
  src: url('../src/static/Sagire.otf');
}

@font-face {
  font-family: 'Bangla';
  src: url('../src/static/Bangla MN.ttf');
}

:root {
  --lighttext-color: #EDE9E6;
  --navbarfooter-color: #032437;
  --navbarunselected-color: #A7AEB1;
  --maindarkblue-color: #011520;
  --textgreycolor: #111111;
  --mainheading-text: #B19278;
  --servicesline-color: #D6D6D6;
  --cardtitle-color: #747373;
  --cardtext-color: #9F9F9F;
  --white-color: #ffffff;
  --cardsubtitle-color: rgba(255, 255, 255, 0.7);
  --tabunselectedtext-color: rgba(249, 248, 247, 0.6);
  --yellowgold-color: #F6DBA3;
  --pinkgold-color: #F0A680;
  --whitegold-color: #E2E3E5;
  --error-color: #DC3444;
  --success-color: rgb(39, 175, 39);
}

hr{
  border-color: var(--lighttext-color) !important;
  opacity: 10% !important;
}

.navbarColour {
  background-color: var(--navbarfooter-color);
}

.logo-font {
  font-size: 50px;
  font-family: Sagire;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: var(--lighttext-color) !important;
}

.nav-font {
  font-family: 'Bangla';
  text-transform: uppercase;
  align-content: center !important;
  /* padding-top: 10%!important; */
  font-size: 15px !important;
  color: var(--navbarunselected-color) !important;
}

.nav-font-selected {
  font-family: 'Bangla';
  text-transform: uppercase;
  align-content: center !important;
  /* padding-top: 10%!important; */
  font-size: 15px !important;
  color: var(--lighttext-color) !important;
}

nav-font:hover {
  color: #EDE9E6 !important;

}

.logo-image {
  padding-right: 10px;
}

.padding-nav {
  padding-left: 100px;
}

h0 {
  font-family: Sagire;
  font-style: normal;
  font-weight: 400;
  color: var(--lighttext-color);
  font-size: 80px;
}
.suggestioncard {
  flex: 0 0 auto !important;
  width: 300px !important ;
  margin-right: 10px !important;
  border-radius: 5px !important;
  padding: 10px!important;
  /* min-width: auto !important; */
}
@media (max-width: 1024px) {
  h0 {
    font-size: 60px;
  }

  .cataloguesubheaders {
    font-size: x-small !important;

  }
  .singleproductheader{
    font-size: x-large;
  }
  .whiteinfobg{
    padding-top: 4% !important;
  }
  .contacttext{
    font-size: x-small !important;
  }  
  .singleprodcontainer{
    margin-top: 10% !important;
    
    }
    .suggestioncard{
      width: 200px !important;
    }
    .mainbodyimg{
      padding-top: 20% !important;
      padding-left: 5% !important;
      padding-right: 5% !important;
      padding-bottom: 5% !important;
    }
}

/* Mobile screens */
@media (max-width: 768px) {
  

  h0 {
    font-size: 40px;
  }

  .footertextheader {
    margin-top: 6%;
  }

  .nav-link.active {
    font-size: small !important;
  }

  .nav-link {
    font-size: small !important;
  }

  .singleprodcontainer{
    margin-top: 20% !important;
    
    }
    .singleproductnav{
    margin-top: 10%;
    }
    
    .singleproductheader{
      font-size: x-large;
    }
  .contacttext{
    font-size: smaller;
  }    
  .whiteinfobg{
    padding-top: 4% !important;
  }
  .suggestioncard{
    width: 200px !important;
  }
  .rewardsimage{
    height: 3.0em !important;
  }
  .rewardsprogresstext{
    font-size: small !important;
  padding-top: 10px !important;

  }
  .rewardsdatetext{
    font-size: x-small !important;
  padding-top: 10px !important;

    
  }
  .rewardsdetails{
    /* padding: 2% !important; */
  }
  .rewardsprogressdiv{
    padding-top: 12% !important;
  }
  .mainbodyimg{
    padding-top: 20% !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    padding-bottom: 5% !important;
  }
}

.abc.scrollmagic-pin-spacer {
  padding-bottom: 0 !important;
}

.primarybutton {
  color: var(--textgreycolor);
  background-color: var(--lighttext-color);
  font-family: Bangla;
  padding-top: 3%;
  font-size: small;
  padding-bottom: 2%;
  padding-left: 4%;
  padding-right: 4%;
  border-width: 0px;
  border-radius: 5px;
}

.mainbody {
  background-color: var(--maindarkblue-color);
}

.image-wrapper {
  position: relative;
  /* background-color: #A7AEB1; */
  width: 100%;
  height: 0;

  padding-bottom: 60%;
  /* Maintain a 1:1 aspect ratio (square) */
}

.image-wrapper img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

}
.about-image-wrapper {
  position: relative;
  /* background-color: #A7AEB1; */
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  margin-bottom: 65%;
  /* Maintain a 1:1 aspect ratio (square) */
}

.about-image-wrapper img {
  position: absolute;
  object-fit: cover;
  /* width: 100%; */
  /* height: 100%; */
  top: 0;
  left: 0;
}

.image-container img {
  display: block;
}
.image-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  text-align: center;
}

.image-container:hover .overlay {
  opacity: 1;
}

.about-image-container img {
  display: block;

}
.about-image-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 210%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  text-align: center;
}

.about-image-container:hover .overlay {
  opacity: 1;
}
.imageoverlaysubtitle{
  font-size: smaller;
  font-family: Arial;
  /* text-transform: uppercase; */
  letter-spacing: 0.1em;
}
.imageoverlaytitle{
  font-family: Arial;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 20%;
  /* text-align: center;
  align-content: center;
  justify-content: center; */
}
.aboutimageoverlaysubtitle{
  font-size: smaller;
  font-family: Arial;
  /* text-transform: uppercase; */
  letter-spacing: 0.1em;
}
.aboutimageoverlaytitle{
  font-family: Arial;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 50%;
}
.row{
  margin: 0 !important;
}
.subheader-text {
  color: var(--lighttext-color);
  font-family: Bangla;
  text-align: center;
  margin-top: 50px;
  letter-spacing: 0.1em;
}

.mainheading-text {
  color: var(--mainheading-text);
  font-family: Bangla;
  text-align: center;
  letter-spacing: 0.1em;
}

.serviceslogo {
  color: var(--mainheading-text);
}

.servicestext {
  color: var(--lighttext-color);
  font-family: Bangla;
  text-align: center;
  letter-spacing: 0.1em;
  margin-top: 10px;
}

.servicesparagraph {
  color: var(--lighttext-color);
  font-family: Arial;
  text-align: center;
  letter-spacing: 0.1em;
}

.servicesline {
  height: 2px;
  background-color: var(--servicesline-color);
  margin-bottom: 30px;
  margin-top: 30px;
}

.aboutparagraph {
  color: var(--lighttext-color);
  letter-spacing: 0.1em;
  font-family: Arial;
  text-align: justify;
}


.card {
  background-color: transparent !important;
}

.card-img-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important
}

.cardtitle {
  font-family: 'Arial';
  color: var(--white-color);
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;

}

.cardtext {
  font-family: 'Arial';
  color: var(--cardsubtitle-color);
  letter-spacing: 0.1em;
  text-align: center;
  /* font-size: smaller; */
}

p {
  font-size: smaller;
}

.footer {
  background-color: var(--navbarfooter-color);
  padding: 6%;
}
/* In your CSS file */
.fixed-catalogue-tabs {
  position: fixed;
  top: 56px; /* Adjust this value based on the height of your navbar */
  width: 100%;
  padding-top: 17px;
  padding-bottom: 15px;
  z-index: 1000; /* Ensure it's above other content */
  background-color: var(--maindarkblue-color); /* Background color to cover content behind it */
}

.copyright-footer{
  background-color: var(--lighttext-color);
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--maindarkblue-color);
}

.footerlogotext {
  font-family: Sagire;
  font-style: normal;
  font-weight: 400;
  color: var(--lighttext-color);
}

.footertextheader {
  color: var(--white-color);
  font-family: Bangla;
  /* text-align: center; */
  font-weight: 400;
  letter-spacing: 0.1em;
  /* margin-top: 10px; */
}

.cataloguesubheaders {
  font-size: small;
  font-family: 'Arial';
  letter-spacing: 0.1em;

  color: var(--lighttext-color);
}

.contactlogos {
  color: var(--lighttext-color);
}

/* TABS */
.centered-tabs {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap; /* Prevent flex items from wrapping */
  overflow-x: auto; 
}

.tabs-wrapper {
  /* width: fit-content; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* margin-bottom: 20px; */
  text-decoration: none;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
  /* white-space: nowrap; */
}



.tab-content.show {
  display: block;
}


.nav-link.active {
  /* position: relative; */
  font-family: 'Bangla' !important;
  color: var(--lighttext-color) !important;
  /* text-decoration: none; */
  text-decoration-line: underline;
  text-transform: uppercase;
  font-size: small;
  border: 0px !important;
  background-color: transparent !important;

}

.nav-tabs {
  justify-content: center;
  border: 0px !important;
  font-family: 'Bangla' !important;
  text-transform: uppercase;
  font-size: small;
  --bs-nav-tabs-border-width: 0px !important;
}

.nav-tabs :hover {
  border: 0px !important;
}

.nav-link {
  color: var(--tabunselectedtext-color) !important;
  display: inline !important;
  margin-right: 15px;
  
}

.nav-link :hover {
  color: var(--tabunselectedtext-color) !important;
  border: 0px !important;

}

a.nav-font{
  /* text-decoration: none !important; */
}

.showcursor{
  cursor: pointer;
}

.anchormainclass{
  color: var(--lighttext-color) !important;
}

.navbarselectedclass{
  text-decoration: none !important;
  color: var(--lighttext-color) !important;
}

.newaarivalslink{
  text-decoration: none;
  color: var(--lighttext-color);
}

.footerlink{
  text-decoration: none;
  color: var(--lighttext-color);
}
/* Card Styles */
.card {
  position: relative;
  display: inline-block; /* Ensure the cards appear in a row */
  margin: 10px;
}

.card img {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.card img:hover {
  transform: scale(1.1); /* Increase the scale on hover */
}

/* Single Product Page */
.singleprodcontainer{
background-color: var(--maindarkblue-color);
margin-left: 10%;
margin-right: 10%;
margin-bottom: 5%;
margin-top: 8%;
padding: 3%;
}

body{
background-color: var(--maindarkblue-color) !important;
}

.singleproductnav{
  color: var(--lighttext-color);
  font-family: 'Bangla';
  font-size: small;
  text-transform: uppercase;
}
.singleproductcategorynav{
  color: var(--lighttext-color);
  text-decoration: none;
}
.singleproductheader{
  color: var(--lighttext-color);
  font-family: 'Sagire';
  text-transform: uppercase;

}

.singleproductgoldclr{
  font-family: Arial;
  font-size: small;
  color: var(--navbarunselected-color);
  text-transform: 0.1em;
  text-transform: uppercase;
}
.singleproductdetailsbold{
  font-weight: bold;
  color: var(--lighttext-color);
}
.singleproductdetailsregular{
  font-weight: 400;
  color: var(--lighttext-color);
}
.singleproductdetaildesc{
  color: var(--lighttext-color);
}
.goldcircle{
  display: inline-block;
  background-color: var(--yellowgold-color);
  height: 20px;
  margin-left: 6%;
  width: 20px;
  border-radius: 20px;
}
.pinkgoldcircle{
  display: inline-block;
  background-color: var(--pinkgold-color);
  height: 20px;
  margin-left: 6%;
  width: 20px;
  border-radius: 20px;
}
.whitegoldcircle{
  display: inline-block;
  background-color: var(--whitegold-color);
  height: 20px;
  margin-left: 6%;
  width: 20px;
  border-radius: 20px;
}
.whiteinfobg{
  background-color: var(--navbarfooter-color);
  color: var(--servicesline-color);
  padding-top: 3%;
  padding-bottom: 1%;
  padding-left: 2%;

  font-family: 'Bangla';
}

.card-list {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  padding-bottom: 10px;
}
.card-list::-webkit-scrollbar {
  display: none;
}
/* .scrollmagic-pin-spacer {
  padding-bottom: 0px !important;
}
.scrollmagic-pin-spacer:last-child {
  padding-top: 1623px !important;
} */


.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: 2.2em;
  font-family: '';
  color: var(--lighttext-color);
  font-family: 'Bangla';
  user-select: none;
}

.indicator-volume {

  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: 1.3em;
}

.progressbarheading{
  color: var(--mainheading-text);
  font-family: 'Bangla';
  text-transform: uppercase;
}

.rewardspoints-text {
  color: var(--lighttext-color);
  font-family: Bangla;
  text-align: center;
  letter-spacing: 0.1em;
}

.rewardsimage{
  height: 6.2em;
}
.rewardsprogresstext{
  color: var(--lighttext-color);
  font-family: 'Bangla';
  /* line-height: 80px ; */
  margin-left: 5px;
  padding-top: 10px !important;

  font-size: large;
}

.rewardsdatetext{
  color: var(--navbarunselected-color);
  font-family: 'Bangla';
  /* line-height: 80px !important; */
  margin-right: 5px;
  /* margin-bottom: 10px; */
  padding-top: 10px !important;

  /* font-size: medium; */

}
.rewardsdetails{
  display: flex;
  padding: 3%;
  border-radius: 5px;
  margin-bottom: 10px;
  /* line-height: 30px; */
  background-color: var(--navbarfooter-color);
}
.rewardsprogressdiv{
  padding: 6%;
}

.loading{
  margin: 0;
  height: 100vh; /* Set the height of the body to the full viewport height */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; 
}
.loadinggif{
  height: 300px;
}

.dropnav-link {
  color: var(--tabunselectedtext-color) !important;
  display: inline !important;
  margin-right: 15px;
  
}

.dropnav-link :hover {
  color: var(--tabunselectedtext-color) !important;
  border: 0px !important;

}

.mainbodyimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../public/images/bglogin.jpg");
    background-size: cover;
    background-position: center;
    padding-top: 6%;
    padding-bottom: 6%;
    padding-right: 6%;
    padding-left: 6%;
}

.mainbodyimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
body, html {
  height: 100%;
  margin: 0;
}

.solidbglogin{
  background-color: var(--navbarfooter-color);
  /* height: 10em; */
}
.loginbtn{
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 5%;
  padding-right: 5%;
  border: 0px;
  border-radius: 5px;
  background-color: var(--lighttext-color);
  color: var(--maindarkblue-color);
}
.logintext{
  color: var(--lighttext-color);
  font-family: 'Bangla';
  margin-top: 10px;
}
.logintextlink{
  color: var(--mainheading-text);
}
.invalidcred{
  color: var(--error-color);
  font-family: 'Bangla';
  margin-top: 10px;
}
.logoutbtn{
  border: 0px;
  background-color: transparent;
  color: var(--lighttext-color);
}
.popoverHeader{
  background-color: var(--mainheading-text) !important;
  border: 0px !important;
  color: var(--lighttext-color) !important;
}
.rewardspopupimage{
  height: 6.2em;
align-content: center;
align-self: center;
}



.navbar-brand{
  margin-right: 0 !important;
}
.navbartoggle{
  border: 0 !important;
}
.togglebtn{
  background-color: transparent;
  border: 0;
  color: var(--lighttext-color);
}
.errortext{
  color: var(--error-color);
  font-family: 'Arial';
  margin-top: -10px;

}
.linksent-text {
  color: var(--lighttext-color);
  font-family: Bangla;
  text-align: left;
  margin-top: 50px;
  letter-spacing: 0.1em;
}
.inquirebutton{
  color: var(--textgreycolor) !important;
  background-color: var(--lighttext-color) !important;
  font-family: Bangla;
  padding-top: 3%;
  font-size: small;
  padding-bottom: 2%;
  padding-left: 4%;
  padding-right: 4%;
  border-width: 0px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.inquiretextarea{
  margin-bottom: 15px;
  background-color: var(--lighttext-color) !important;
  color: var(--navbarfooter-color) !important;
}

.inquiretextarea::placeholder{
  color: var(--cardtext-color) !important;
  font-size: small;
}
.successmsg{
  color: var(--success-color);
  font-family: 'Bangla';
}
.namevaluecontainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.maincolor{
  color: var(--mainheading-text);
}

.loadingprods{
  color: var(--lighttext-color);
}