.modalimage{
    width: 100%;
    height: auto;
    cursor: url('./X.svg'), auto;
}
.modalcontent{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.mainmodal{
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}
.modalimgcontainer{
    width: 100%;
}
.fullmodalimage{
    width: 100%;
}